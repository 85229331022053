/* styles.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5f9;
}

.h2, h2 {
  font-size: 24px;
}

.h3, h3 {
  font-size: 20px;
}

.btn-primary {
  background-color: #405cdc;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 100%;
}

.row {
  padding-left: 0px;
  padding-right: 0px;
}

.page-row {
  padding-left: 15px;
}

.dashboard {
  display: flex;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.logo {
  /* Styling for the logo */
}

.menu {
  /* Styling for the sidebar menu */
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Styling for the top navigation bar */
}

.hamburger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 20px;
  cursor: pointer;
}

.bar {
  width: 20px;
  height: 3px;
  background-color: #fff; /* Adjust color as needed */
  margin: 2px 0;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #202434; /* Blue sidebar banner color */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  color: #fff; /* Text color */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0; /* Extend to the bottom of the viewport */
  overflow-y: auto; /* Allow scrolling within the sidebar if it overflows */
  transition: none; /* Disable transitions on initial render */
}

.sidebar.initial-render {
  transition: width 0.3s ease; /* Enable transition after initial render */
}

.container {
  margin-left: 250px; /* Adjust margin to match the sidebar width */
  padding: 0px;
 /* Smooth transition */
}

.content {
  width: calc(100% - 250px);
  transition: width 0.3s ease; /* Smooth transition */
}

.sidebar.collapsed {
  width: 60px; /* Collapsed sidebar width */
}

.sidebar.collapsed + .container {
  margin-left: 60px; /* Adjust container margin when sidebar is collapsed */
}

.sidebar-header {
  display: flex;
  align-items: center;
}

.sidebar-logo img {
  width: 50px; /* Adjust your logo size as needed */
  height: 50px;
  margin-right: 10px;
}

.sidebar-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.toggle-icon.collapsed {
  transform: rotate(0deg);
}

.toggle-icon {
  transition: transform 0.3s ease;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin-bottom: 20px;
}

.sidebar-menu a {
  text-decoration: none;
  color: rgb(222 228 238);
  display: flex;
  align-items: center;
}

.sidebar-menu a:hover {
  color: #fff;
}

.menu-icon {
  margin-right: 10px;
  font-size: 18px; /* Adjust icon size as needed */
}

.subscription-cta {
  margin: 20px 0;
}

.subscription-cta a {
  text-decoration: none;
  color: #fff;
  background-color: #2980b9; /* CTA button background color */
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.cta-icon {
  margin-right: 10px;
  font-size: 18px; /* Adjust icon size as needed */
}

.userfront-secured {
  visibility: hidden;
  display: none;
}




/* Wrapper for responsive table */
.table-responsive {
  overflow-x: auto; /* Allows horizontal scrolling if the table is too wide */
  -webkit-overflow-scrolling: touch; /* Adds smooth scrolling on touch devices */
}

/* Style for the entire table */
.table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells do not overlap */
}

/* Style for table header */
.table thead th {
  background-color: #f1f5f9; /* Background color for table header */
  color: #333; /* Optional: Adjust text color for better contrast */
  border: none; /* Remove borders from header cells */
  padding-top: 18px; /* Padding for header cells */
  padding-bottom: 18px; /* Padding for header cells */
  text-align: left; /* Align text to the left in header cells */
}

/* Style for table rows */
.table tbody tr {
  border-bottom: 1px solid #ddd; /* Light gray border between rows */
}

/* Style for table cells */
.table td, .table th {
  background-color: #fff; /* White background for cells */
  padding: 8px; /* Padding for cells */
  border: none; /* Remove default border */
}

/* Optional: Remove border from the last row */
.table tbody tr:last-child {
  border-bottom: none;
}

/* Optional: Add some spacing at the bottom of the table for better appearance */
.table {
  margin-bottom: 1rem;
}








/* Style for inactive status */
.status-label-inactive {
  background-color: #f0f0f0; /* Light gray background for inactive */
  color: #6c757d !important; /* Gray text color */
}




.row {
  margin-right: 0 !important;
}




/* Overlay */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); /* Frosted glass effect */
}

.modal-content {
  border-radius: 12px; /* Adjust for your preference */
}

/* Header */
.modal-header {
  border-bottom: none; /* Custom underline */
  padding: 1.5rem;
  background-color: #f1f5f9;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* Footer */

.modal-dialog {
  max-width: 800px !important;

}

.modal-footer {
  border-top: none; /* Custom underline */
  padding: 1.5rem;
  background-color: #f1f5f9;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: start;
  
}

/* Customize buttons */
.modal-footer .btn-primary {
  background-color: #405cdc; /* Your primary brand color */
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.modal-footer .btn-primary:hover {
  background-color: #324bb2; /* Slightly darker on hover */
}

/* Typography */
.modal-title {
  font-family: 'Roboto', sans-serif; /* Example of a modern font */
  font-weight: 500;
  color: #333;
}

/* Fade and slide in animation */
.modal.fade .modal-dialog {
  transform: translateY(-20px); /* Initial position for slide effect */
  transition: transform 0.3s ease-out, opacity 0.3s ease;
}
.modal.show .modal-dialog {
  transform: translateY(0); /* Final position */
}