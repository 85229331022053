.react-flow__attribution {
  display: none;
  visibility: hidden;
}

.react-flow__panel.top {
  top: 0;
  min-width: 350px;
  min-height: 400px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
}

.panel-workflow {
  background-color: #fafafa;
}


.reactflow-wrapper {
  background-color: #f1f5f9;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {

  .react-flow__panel.top {
    top: 0;
    min-width: 100% !important;
    min-height: 400px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
  }

  .react-flow__panel {
    position: absolute;
    z-index: 5;
    margin: 0px;
}
  
}

.node-start, .node-end {
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #405cdc; /* Green for start */
  color: white;
  font-weight: bold;
}

.node-end {
  background-color: transparent; /* Red for end */
  color: #000;
  border: none;

}



.node-end .react-flow__handle-bottom {
  visibility: hidden;
}


.node-start .react-flow__handle-top {
  visibility: hidden;
}







.node-default {
  border-radius: 8px; /* Default nodes with rounded corners */
  padding: 10px;
  background-color: #2196F3; /* Blue for default nodes */
  color: white;
}

.node-not-accepted {
  border: none;
  background-color: transparent;
}

.node-accepted {
  border: none;
  background-color: transparent;
}


.node-no-response {
  border: none;
  background-color: transparent;
}

.node-response {
  border: none;
  background-color: transparent;
}

.node-timer {
  border: none;
  padding: 20px;
}

.node-message {
  border: none;
  padding: 20px;
}

.node-connection-request {
  border: none;
  padding: 20px;
}


.node-profile-view {
  border: none;
  padding: 20px;
}

/* Timer Node */
.node-timer::before {
  content: '\f017'; /* Clock icon */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: #405cdc; 
  font-size: 20px;
}

/* Message Node */
.node-message::before {
  content: '\f0e0'; /* Envelope icon for messages */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: #405cdc; 
  font-size: 20px;
}

/* Connection Request Node */
.node-connection-request::before {
  content: '\f0c0'; /* Users icon for connection requests */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: #405cdc; 
  font-size: 20px;
}

/* Response Node */
.node-response::before {
  content: '\f075'; /* Speech bubble icon for response */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: green; 
  font-size: 20px;
}


/* No Response Node */
.node-no-response::before {
  content: '\f00d'; /* Times icon for no response */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: red; 
  font-size: 20px;
}

/* Accepted Node */
.node-accepted::before {
  content: '\f00c'; /* Check icon for accepted */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: green; 
  font-size: 20px;
}

/* Not Accepted Node */
.node-not-accepted::before {
  content: '\f00d'; /* Times icon for not accepted */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: red; 
  font-size: 20px;
}


/* Not Accepted Node */
.node-profile-view::before {
  content: '\f06e'; /* Times icon for not accepted */
  font-family: 'Font Awesome 5 Free'; 
  font-weight: 900; 
  position: absolute; 
  left: 10px; 
  top: 50%; 
  transform: translateY(-50%); 
  color: #405cdc; 
  font-size: 20px;
}