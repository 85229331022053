input {
    margin-bottom: 5px;
}

h2 {
    margin-top: 25px;
}

.limits-form .form-group {
    margin-bottom: 20px;
  }
  
  .slider-value {
    font-size: 16px;
    margin-top: 5px;
  }

  
.notifications-checkbox {
  margin-right: 5px;
}



.btn-save-notifications {
  margin-top: 15px;
}



  .profile-image-container {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.3s ease;
  }
  
  .profile-image-container:hover .image-overlay {
    opacity: 1;
  }
  
  .upload-icon {
    color: white;
    font-size: 2rem;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  



  .profileImageWrapper {
    position: relative;
    display: flex;
    align-items: center; /* Center align items */
    justify-content: center; /* Center align items */
    width: 100px; /* Set width as per your requirement */
    height: 100px; /* Set height as per your requirement */
    border: 2px dashed #ccc; /* Dashed border for placeholder */
    border-radius: 50%; /* Make it circular */
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.profileImage {
    width: 100%; /* Cover the entire wrapper */
    height: 100%; /* Cover the entire wrapper */
    object-fit: cover; /* Ensure image scales correctly */
    border-radius: 50%; /* Make it circular */
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Match the wrapper's width */
    height: 100%; /* Match the wrapper's height */
    color: #aaa; /* Placeholder color */
    position: absolute; /* Position over the image */
}









/* Individual card styling */
.integration-card {
  background-color: #f1f5f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 95%;
}

/* Placeholder cards for integrations not yet available */
.placeholder-card {
  background-color: #f9f9f9;
}

/* Styling for integration logos */
.integration-logo {

  height: 35px;
  margin-bottom: 15px;
}

.integration-card h4 {
  margin: 10px 0;
  font-size: 1.2em;
}

.integration-card p {
  color: #777;
}
