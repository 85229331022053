.Login {
    text-align: center;
    margin-top: 100px;
}

.pageTitle {
    margin-bottom: 30px;
}
.logo {
    margin-bottom: 30px;
}

.container {
    margin-left: 0px;
}


/* Hide the first form row when resetting password for the first time */
.hide-first-row .userfront-form-row:first-of-type {
    display: none;
  }
  