/* Messages.css */

/* Add this to your Messages.css */


.empty-inbox-img {
  max-width: 350px;
}

.empty-inbox-div {
  text-align: center;
}

/* Style for unread messages */
.unread-message {
  background-color: #f2f2f2; /* Add your desired background color */
  font-weight: bold;
}

/* Style for the filter with unread count */

/* Style for the unread count badge */
.unread-badge {
  background-color: #405cdc; /* Add your desired badge background color */
  color: #fff;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 5px;
}


/* Style for the filter section */
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Style for the search input */
  .search-bar {
    flex-grow: 1;
   
  }
  
  /* Style for the "Create New Campaign" button */
  .create-button {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  




  /* Messages.css */

/* Styling for the conversation list */
.conversation-list {
  list-style: none;
  padding: 0;
}

/* Styling for each conversation block */
.conversation-list li {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

/* Style for conversation list item when there is a new message */
.conversation-list li.new-message {
  background-color: #f0f0f0;
}

/* Styling for user profile avatar (you can add your own styles or use an actual image) */
.profile-avatar {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

/* Styling for conversation details */
.conversation-details {
  flex: 1;
}

/* Styling for conversation name (person's name) */
.conversation-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for company (subtitle) */
.company {
  font-size: 14px;
  color: #666;
}

/* Styling for last message in the conversation */
.last-message {
  font-size: 16px;
}

/* Styling for the conversation textarea */
.conversation-textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-top: 20px;
  resize: vertical; /* Allow vertical resizing of the textarea */
  min-height: 80px; /* Set a minimum height for the textarea */
}

/* Styling for the send button */
.send-button {
  margin-top: 10px;
  background-color: #405cdc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Style for the send button on hover */
.send-button:hover {
  background-color: #0056b3;
}


/* Messages.css */

/* Add a style for conversation cards with new messages */
.conversation-card.new-message {
  background-color: #f0f0f0; /* Change this to your desired highlight color */
}

/* Style for user information */
.user-info {
  font-weight: bold;
}

/* Style for the message input area */
.message-input {
  margin-top: 10px;
}

/* Style for your own messages */
.own-message {
  background-color: #d9f4fc; /* Change this to your desired own message background color */
}








.message-list {
  margin-top: 75px;
}








.message-list-container {
  display: flex;
  justify-content: space-between;

}

.lead-list {
  flex: 1;
  padding-right: 20px;
}

.lead-list h2 {
  margin-bottom: 10px;
}

.lead-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lead-list li {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.message-panel {
  flex: 2;
}

.message-list {
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  max-height: 400px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.message .sender {
  font-weight: bold;
  margin-bottom: 5px;
}




.dashboard {
  display: flex;
  height: 100vh;
}

.container {
  flex: 1;
  padding: 0;
}

.message-list-container {
  display: flex;
}

.lead-list {
  flex: 1;
}

.message-panel {
  flex: 3;
  margin-left: 20px;
}

.message-list {
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid #ccc;
  padding: 10px;
}

.message {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message.unread {
  background-color: #d4edda; /* Red background color */
}

.message.read {
  background-color: #f1f5f9; /* Green background color or any other color for read messages */
}

.message:hover {
  background-color: #e0e0e0;
}



.sender {
  font-weight: bold;
}

.unread-count {
  position: absolute;
  top: 0;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}


.messages-leads-list {
  margin-right: 5px;
}

/* Styling for the unordered list */
.lead-list {
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

/* Styling for each list item */
.lead-item {
  display: flex; /* Flexbox to align items horizontally */
  align-items: center; /* Center items vertically */
  padding: 10px; /* Add some padding */
  margin-bottom: 10px; /* Space between list items */
  cursor: pointer; /* Change cursor to pointer on hover */
  background-color: #fff;
}



/* Styling for the circle avatar with the first letter */
.lead-initial {
  width: 50px; /* Same size as the image */
  height: 50px; /* Same size as the image */
  background-color: #405cdc; /* Background color for the avatar */
  color: #fff; /* Text color for the initial */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: 50%; /* Make it a circle */
  font-size: 1.5rem; /* Font size for the initial */
  font-weight: bold; /* Bold initial */
  margin-right: 15px; /* Space between avatar and lead name */
  flex-shrink: 0; /* Prevent shrinking */
  max-width: 100%; /* Ensure the avatar scales appropriately */

}

/* Media query for smaller screens to adjust the size */
@media (max-width: 768px) {
  .lead-avatar, .lead-initial {
    width: 40px; /* Adjust size for smaller screens */
    height: 40px; /* Adjust size for smaller screens */
  }
  .lead-name {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}


/* Styling for the lead name */
.lead-name {
  flex-grow: 1; /* Allow the name to take up remaining space */
  font-size: 1rem; /* Font size for the name */
}

/* Styling for the unread message badge */
.unread-badge {
  background-color: #ff0000; /* Red background color for the badge */
  color: #fff; /* White text color */
  border-radius: 50%; /* Circle badge */
  padding: 5px 10px; /* Padding inside the badge */
  font-size: 0.8rem; /* Smaller font size */
  margin-left: 10px; /* Space between the badge and the text */
}



/* Styling for the search input */
.lead-search-input {
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */
  margin-bottom: 20px; /* Space below the input */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Font size */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}






.message-widget {
  background-color: #ffffff;
  border-radius: 0.125rem;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}



/* Styles for messages sent by the Userfront user */
.my-message {
  background-color: #405cdc !important; /* Light blue for user messages */
  align-self: flex-end; /* Align user messages to the right */
  color: #000; /* Optional: Change text color */
  width: 85%;
  margin-left: 15%;
  color: #fff;
}

/* Styles for messages sent by the lead */
.lead-message {
  background-color: #f2f2f2; /* Light gray for lead messages */
  align-self: flex-start; /* Align lead messages to the left */
  color: #000; /* Optional: Change text color */
  width: 85%;
  margin-right: 15%;
}

/* Base style for messages */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column; /* Stack sender and text vertically */
}

/* Style for message sender */
.sender {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Style for message text */
.text {
  margin-top: 5px; /* Add spacing for message text */
}

/* Adjust message container to flex */
.message-list {
  display: flex;
  flex-direction: column; /* Stack messages vertically */
}











.message-input {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.message-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.send-message-button, .generate-response-button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #405cdc;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-message-button:hover, .generate-response-button:hover {
  background-color: #0056b3;
}

.ai-response {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f4f4;
  border-left: 4px solid #405cdc;
  border-radius: 5px;
}

.ai-response h3 {
  margin: 0;
  font-size: 1.2rem;
}

.ai-response p {
  margin-top: 10px;
  font-size: 1rem;
}
