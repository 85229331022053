/* Campaigns.css */

/* Style for the filter section */
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Style for the search input */
  .search-bar {
    flex-grow: 1;
  }
  
  /* Style for the "Create New Campaign" button */
  .startNewCampaignButton {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  

  .manageButton {
    margin-right: 5px;
  }


  .campaigns-table {
    margin-top: 10px;
  }