.container-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(300deg, rgb(237,239,242) 1%, rgb(0,123,255) 100%);
}

.confirmation {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.confirmation-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.confirmation-message {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-button {
  padding: 0.7rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #405cdc;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.form-button:hover {
  background-color: #0056b3;
}

.confirmation-links {
  margin-top: 1rem;
}

.confirmation-link {
  display: block;
  margin-top: 0.5rem;
  color: #405cdc;
  text-decoration: none;
}

.confirmation-link:hover {
  text-decoration: underline;
}
