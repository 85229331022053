.container-register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(300deg,rgb(237,239,242) 1%,rgb(0,123,255) 100%);
}

.signup {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.signup-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.form-step {
  display: flex;
  flex-direction: column;
}

.form-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.form-label {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
}

.form-input {
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.password-requirements {
  font-size: 0.8rem;
  color: #666;
  text-align: left;
  margin-bottom: 1rem;
}

.password-strength {
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.password-strength-bar {
  height: 100%;
  transition: width 0.3s ease;
  background-color: red; /* Default color, will be overwritten */
}

.form-button {
  padding: 0.7rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #405cdc;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.form-button:hover {
  background-color: #0056b3;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  font-weight: bold;
  color: #405cdc;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 1rem;
  transition: color 0.3s;
  text-decoration: none;
}

.back-button:hover {
  color: #0056b3;
}

.back-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}
