.onboarding-widget {

    display: flex;
    flex-wrap: wrap;
 
    max-width: 100%;
}

/* Left Column: Onboarding Steps */
.onboarding-steps-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Right Column: Tutorial */
.onboarding-tutorial-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;

    margin-top: 20px;
}

/* Step Description */
.onboarding-step {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.step-icon {
    margin-right: 10px;
}

.step-description {
    flex: 1;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .onboarding-widget {
        flex-direction: column;
    }

    .onboarding-steps-container,
    .onboarding-tutorial-container {
        flex: unset;
        width: 100%;
    }
}

  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .onboarding-widget {
      padding: 15px;
    }
  
    .onboarding-step {
      font-size: 0.9rem;
    }
  
    .header-container h2 {
      font-size: 1.3rem;
    }
  
    .header-container .btn {
      width: 33%;
      text-align: center;
    }
  
    .onboarding-steps-container {
      gap: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .onboarding-widget {
      padding: 10px;
    }
  
    .onboarding-step {
      font-size: 0.8rem;
    }
  
    .header-container h2 {
      font-size: 1.2rem;
    }
  
    .header-container .btn {
      font-size: 0.9rem;
      width: 50%;

    }


    .floating-icon {
        visibility: hidden;
        display: none;
    }
  }
  



 
  
  .tutorial-link, .help-center-link {
    margin: 10px 0;
    color: #007bff;
    text-decoration: none;
  }
  
  .tutorial-link:hover, .help-center-link:hover {
    text-decoration: underline;
  }



  .onboarding-widget {
    position: relative; /* Ensure the widget is positioned relative */
    /* Other styles as needed */
  }
  
  .floating-icon {
    position: absolute; /* Use absolute positioning */
    bottom: 20px; /* Distance from the bottom of the widget */
    right: 20px; /* Distance from the right of the widget */
    width: 200px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Change cursor to pointer for better UX */
    z-index: 1000; /* Ensure it's above other content */
  }
  


  .step-link {
    text-decoration: none;
  }