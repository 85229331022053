/* CampaignDetail.css */

.campaign-detail {
  padding: 0px;
}

.progress {
  height: 30px;
  margin-bottom: 20px;
}

.saveLeadListCampaignButton {
  margin-top: 10px;
}

.progress-bar-striped {
  background-color: #405cdc !important;
}