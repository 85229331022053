.header-container {
    display: flex;
    justify-content: space-between; /* Distribute space between elements */
    align-items: center; /* Vertically center elements */
  }
  
  .header-info {
    display: flex;
    align-items: center; /* Vertically center the content within this container */
    margin-top: 10px;
  }
  
  .header-info p {
    margin-right: 10px; /* Optional: Adds space between text and button */
    margin-top: 10px;
  }
  

  .available-seats-container {
    background-color: #f1f5f9; /* Light gray background */
    border-radius: 12px; /* Rounded corners */
    padding: 8px 16px; /* Padding inside the container */
    margin-right: 10px; /* Space between text and button */
    display: flex;
    align-items: center; /* Center items vertically */
  }
  
  .available-seats-text {
    color: #28a745; /* Green text for the number of seats */
    font-weight: bold; /* Optional: Bold text */
  }
  
  .available-seats-label {
    margin-right: 8px; /* Space between label and number */
    color: #333; /* Darker text color for the label */
  }
  


  .text-danger {
    color: red;
  }
  
  .text-success {
    color: green;
  }