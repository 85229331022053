
  
  .plan-selection .row {
    display: flex;
  }
  
  .plan-card {
    transition: all 0.3s ease;
    padding: 20px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .plan-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .plan-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .toggle-switch {
    margin: 10px 0;
  }
  
  .toggle-switch .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #007bff;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #007bff;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  


  .progress-bar-container {
    display: flex;
    margin-bottom: 20px;
  }
  
  .progress-step {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #f1f5f9; /* Change to your theme color */
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-right: 5px;
  }
  
  .progress-step.active {
    background-color: #405cdc; /* Active step color */
    color: white;
  }
  
  .progress-step:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Light hover effect */
  }
  

  


  .checkout-summary-invoice {
    padding: 10px;
    background-color: #f1f5f9;
    margin-bottom: 15px;
  }