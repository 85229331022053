
  
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .chart-header h2 {
    margin: 0;
  }
  
  .cog-icon-button {
    cursor: pointer;
  }
  
  .controls {
    display: flex;
    gap: 15px; /* Ensure there’s space between buttons */
    background-color: #f1f5f9;
    padding: 10px;
    position: relative;
    z-index: 10;
    margin-top: -3px;
  }

  .graph-filter-span {
    margin-top: 5px;
    font-size: 14px;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-button {
    background-color: #405cdc;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f5f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%;
    left: 0;
  }
  
  .dropdown-content.show {
    display: block;
  }
  
  .dropdown-button:focus + .dropdown-content,
  .dropdown-content:hover {
    display: block;
  }
  
  .dropdown-content button {
    background-color: #f1f5f9;
    color: black;
    padding: 10px 15px;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  
  .dropdown-content button:hover {
    background-color: #ddd;
  }
  
  .action-selector .multi-select {
    display: flex;
    flex-direction: column;
  }
  
  .action-selector .multi-select label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .action-selector .multi-select input {
    margin-right: 8px;
  }


  .btn-chart-filter {
    height: 35px !important;
  }
  





.cog-icon-button {
    color: #405cdc;
}

