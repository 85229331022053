/* Campaigns.css */


/* Style for the filter section */
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Style for the search input */
  .search-bar {
    flex-grow: 1;
    margin-right: 0px;
  }
  
  /* Style for the "Create New Campaign" button */
  .create-button {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  

  .importButtonLeads {
    margin-right: 5px;
  }

  .float-right {
    float: right;
  }



  /* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #f1f5f9;
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-button.active {
  background-color: #405cdc;
  color: white;
  border-color: #405cdc;
}

.ellipsis {
  padding: 8px 12px;
  color: #888;
}







.col-settings {
  padding-right: 0px;
  padding-left: 0px;
}

.search-bar {
  margin-right: 5px;
} 



.pagination {
  float: right;
}



.btn-link {
  text-decoration: none;
}

.filter-controls {
  margin-right: 5px;
}