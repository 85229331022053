/* Login.css */

.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(300deg,rgb(237,239,242) 1%,rgb(0,123,255) 100%);
}

.login {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.login-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
}

.form-input {
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.form-button {
  padding: 0.7rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #405cdc;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.form-button:hover {
  background-color: #0056b3;
}

.login-message {
  margin-top: 1rem;
  color: red;
}

.login-links {
  margin-top: 1rem;
}

.login-link {
  margin-top: 0.5rem;
  color: #405cdc;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}
