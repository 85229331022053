/* Navbar.css */

/* Styling for the logo in mobile view */
.logo-mobile {
  width: 50px; /* Adjust logo size */
  height: auto;
  visibility: hidden;
  display: none;
}

.support-icon {
  font-size: 1.5rem;
  color: #405cdc;
}

.topNavBar {
  justify-content: space-between; /* Align content horizontally */
  height: 80px;
}

.navbar-right {
  float: right;
}


.trial-notification {
  margin-left: 20px;
}

.topNavBarRight {
  margin-bottom: 0px;
}

.user-info {
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  margin-right: 15px; /* Space on the right side */
  cursor: pointer; /* Make the entire section clickable */
}

.user-initial {
  width: 40px; /* Size for the avatar */
  height: 40px; /* Size for the avatar */
  background-color: #405cdc; /* Background color for the avatar */
  color: #fff; /* Text color for the initial */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: 50%; /* Make it a circle */
  font-size: 1.2rem; /* Font size for the initial */
  font-weight: bold; /* Bold initial */
  margin-right: 0px; /* Space between avatar and user details */
}

.user-details {
  display: flex;
  flex-direction: column; /* Stack name and role vertically */
  margin-right: 10px;
}

.user-name {
  font-weight: bold; /* Bold text for the user name */
  font-size: 0.9rem; /* Adjust font size */
  color: #333; /* Text color for name */
  text-align: right;
}

.user-role {
  font-size: 0.8rem; /* Slightly smaller font for the role */
  color: #666; /* Text color for role */
  text-align: right;
}

/* Updated styles for the dropdown icon */
.dropdown-icon {
  margin-left: auto; /* Ensure arrow stays on the right */
  font-size: 1.2rem; /* Adjust the size of the arrow */
  color: #333; /* Color of the arrow */
  display: flex; /* Flex display for centering */
  align-items: center; /* Center arrow vertically */
  margin-left: 10px; /* Add some margin to the left */
  cursor: pointer; /* Make the arrow clickable */
}

/* Adjusting caret for visibility */
.dropdown-icon .caret {
  font-size: 30px; /* Increase caret size */
  margin-left: 5px; /* Space from the left */
}

/* Ensures the dropdown is always visible within the viewport */
.topNavBar .dropdown-menu {
  position: absolute; /* Position it relative to the nearest positioned ancestor */
  right: 0; /* Align it to the right side */
  margin: 20px; /* Adds a margin to ensure it's not too close to the edge */
  min-width: 200px; /* Minimum width for better appearance */
  z-index: 1050; /* Ensure it stays on top of other content */
  border-radius: 0.125rem; /* Add a small border-radius for aesthetics */
  padding: 10px 0; /* Padding for dropdown items */
  --bs-dropdown-link-active-bg: var(--bs-tertiary-bg);
}

/* Adjust the positioning of the dropdown within the navbar */
.topNavBarRight .dropdown-menu.show {
  display: block; /* Ensures the dropdown is visible when clicked */
  top: 48px; /* Adjusted to sit nicely below the navbar */
  left: auto; /* Disable any automatic left positioning */
  right: 0; /* Ensure it aligns to the right */
  margin: 15px; /* Keeps a gap between the menu and screen edge */
}

/* Additional styling for the dropdown items */
.navbar .dropdown-item {
  padding: 10px 20px; /* Adds padding for a better user experience */
  color: #333; /* Text color */
  font-size: 0.95rem; /* Adjust font size */
}

.custom-dropdown .dropdown-menu {
  margin: 20px; /* Add margin to keep the dropdown within view */
  min-width: 200px; /* Ensure the dropdown is wide enough */
}


.user-info {
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  margin-right: 15px; /* Space on the right side */
  cursor: pointer; /* Make the entire section clickable */
  flex-wrap: nowrap; /* Prevent wrapping to keep everything on one line */
}

.dropdown-icon {
  margin-left: 10px; /* Margin to the left */
  display: flex; /* Flex display for centering */
  align-items: center; /* Center icon vertically */
  cursor: pointer; /* Make the arrow clickable */
  flex-shrink: 0; /* Prevent shrinking */
}

/* Style for the custom caret */
.custom-caret {
  font-size: 0.8rem; /* Set the size of the custom caret */
  color: #333; /* Set the color of the custom caret */
}

.dropdown-toggle::after {
  display: none;
}

.menu-icon-blue {
  color: #405cdc; /* Adjust icon color */
}



/* Standard CSS */
.support-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem; /* Adjust width */
  height: 2rem; /* Adjust height */
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.support-icon {
  font-size: 1rem; /* Adjust icon size */
  color: #405cdc; /* Adjust icon color */
}

.tooltip.show {
  background-color: #405cdc !important; /* Change background color */
  color: #fff !important;               /* Change text color */
  border-radius: 0.25rem;               /* Optional: Adjust border radius */
  opacity: 1;                           /* Ensure full visibility */
}

/* Tooltip inner content styling */
.tooltip-inner {
  background-color: #405cdc !important; /* Change background color */
  color: #fff !important;               /* Change text color */
  border-radius: 0.25rem;               /* Optional: Adjust border radius */
}

/* Tooltip arrow styling */
.tooltip .tooltip-arrow::before {
  content: "";
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  height: 0;
  border-width: 0.4rem; /* Adjust size of the arrow */
}

/* Tooltip arrow for top placement */
.bs-tooltip-top .tooltip-arrow::before {
  border-width: 0.4rem 0.4rem 0;
  border-color: #405cdc transparent transparent transparent !important; /* Top arrow */
}

/* Tooltip arrow for right placement */
.bs-tooltip-end .tooltip-arrow::before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-color: transparent #405cdc transparent transparent !important; /* Right arrow */
}

/* Tooltip arrow for bottom placement */
.bs-tooltip-bottom .tooltip-arrow::before {
  border-width: 0 0.4rem 0.4rem;
  border-color: transparent transparent #405cdc transparent !important; /* Bottom arrow */
}

/* Tooltip arrow for left placement */
.bs-tooltip-start .tooltip-arrow::before {
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-color: transparent transparent transparent #405cdc !important; /* Left arrow */
}





.profile-image-navbar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}