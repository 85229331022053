/* General sidebar styling */
.sidebar-logo, .hamburger {
    margin-bottom: 50px;
}

.sidebar.collapsed .sidebar-menu a {
    margin-left: 0; /* Ensure all items are aligned to the left in collapsed state */
}

.sidebar-menu li {
    margin-bottom: 30px;
}

.sidebar-logo {
    text-align: center;
    left: auto;
    right: auto;
}

.sidebar-logo img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-left: 5px;
}

/* Adjust container margin when sidebar is collapsed */
.sidebar.collapsed ~ .container {
    margin-left: 60px; /* Adjust as needed for the collapsed state */
}

.sidebar.uncollapsed ~ .container {
    margin-left: 250px; /* Adjust as needed for the uncollapsed state */
}

/* Mobile styles */
@media (max-width: 767px) {
    .sidebar {
        visibility: hidden;
        display: none;
    }

    .logo-mobile {
        visibility: visible !important;
        display: block !important;
    }

    .container, .container-sm {
        max-width: 100%;
        margin-left: 0; /* Remove left margin on mobile */
    }

    .sidebar.collapsed ~ .container {
        margin-left: 0px; /* Adjust as needed for collapsed state */
    }

    .sidebar.uncollapsed ~ .container {
        margin-left: 0px; /* Adjust as needed for uncollapsed state */
    }
}

/* Active menu item styling */
.sidebar-menu li.active-menu-item a {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 10px;
    margin-left: 0; /* Remove margin offset */
    width: 100%; /* Ensure full-width background */
    text-align: center; /* Center text/icon */
}

.sidebar-menu li.active-menu-item a:hover {
    background-color: rgba(255, 255, 255, 0.3);
}


.sidebar-menu li.active-menu-item a {
    color: #fff !important;
  }

/* Ensure vertical alignment for icons in collapsed state */
.sidebar.collapsed .sidebar-menu {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center all icons vertically */
}

.sidebar.collapsed .sidebar-menu li {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px; /* Adjust spacing between items */
}

.sidebar.collapsed .sidebar-menu a {
    display: flex;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    margin-left: 0;
    width: 100%; /* Ensure proper width */
}



/* Only apply these styles when the sidebar is uncollapsed */
.sidebar.uncollapsed .menu-icon {
    margin-right: 10px;
    font-size: 18px;
}




/* Default styles for menu icons, e.g., in collapsed state */
.menu-icon {
    margin-right: 0; /* No margin in collapsed state */
    font-size: 18px; /* Larger icons in collapsed state */
}
